import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy, EventEmitter, HostListener } from '@angular/core';
import { DataService }        from '@omnilib/services/data.service';
import { DateService }        from '@omnipas/services/date.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { LanguageService }    from '@omnipas/services/language.service';
import { ListService }        from '@omnilib/services/list.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';
import { format, parse  }     from 'date-fns';

@Component({
  selector: 'omnipas-cardpreview',
  templateUrl: './cardpreview.component.html'
})
export class CardPreviewComponent implements OnInit, OnDestroy {

  @ViewChild('cardpreviewcontainer') public cardpreviewcontainer: ElementRef;
  @ViewChild('cardpreview')          public cardpreview:          ElementRef;
  @ViewChild('name')                 public name:                 ElementRef;

  firstName:      string  = '';
  fileinput:      string  = '';
  startdate:      string  = '';
  activatedon:    string  = '';
  enddate:        string  = '';
  expdate:        string  = '';

  cardholderfreefield1: string = '';

  cardOrigWidth:  number  = 0;
  cardOrigHeight: number  = 0;

  what:           string = '';
  warning:        string = '';

  initialized:    boolean = false;

  currentPhoto:   string = '';

  cardaslink:     boolean = false;
  title:          string = '';

  constructor ( public data:        DataService
              , public date:        DateService
              , public environment: EnvironmentService
              , public language:    LanguageService
              , public list:        ListService
              , public photo:       PhotoService
              , public router:      RouterService
              , public renderer:    Renderer2
              ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {

    if ( !this.router.toosmall ) {

      const cardpreview          = this.cardpreview.nativeElement;
      const cardpreviewcontainer = this.cardpreviewcontainer.nativeElement;

      if ( this.cardOrigWidth == 0 ) {
        this.cardOrigWidth  = cardpreview.offsetWidth;
      }

      const newWidth  = Math.min( cardpreviewcontainer.offsetWidth, this.cardOrigWidth );
      const factor    = newWidth/cardpreview.offsetWidth;

      this.renderer.setStyle( cardpreview, 'width', newWidth + 'px');
      this.renderer.setStyle( cardpreview, 'height', ((cardpreview.offsetHeight*factor) + 'px'));

      for ( let child of cardpreview.children ) {
        if ( window.getComputedStyle(child, null).getPropertyValue('display') != 'none' ) {
          this.renderer.setStyle ( child
                                 , 'font-size'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('font-size')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'letter-spacing'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('letter-spacing')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'top'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('top')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'left'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('left')
                                                    , factor
                                                    )
                                 );

          this.renderer.setStyle ( child
                                 , 'line-height'
                                 , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('line-height')
                                                    , factor
                                                    )
                                 );

          if ( child.classList.contains('photo') ) {
            let newWidth = child.offsetWidth * factor;

            this.renderer.setStyle ( child
                                   , 'width'
                                   , newWidth + 'px'
                                   );
            this.renderer.setStyle ( child
                                   , 'height'
                                   , ( child.classList.contains('photo') ? (newWidth/3)*4 : child.offsetHeight * factor ) + 'px'
                                   );

          } else if ( child.classList.contains('barcode') ) {
            this.renderer.setStyle ( child
                                   , 'width'
                                   , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('width')
                                                      , factor
                                                      )
                                   );

            this.renderer.setStyle ( child
                                   , 'height'
                                   , this.getNewValue ( window.getComputedStyle(child, null).getPropertyValue('height')
                                                      , factor
                                                      )
                                   );

            for ( let barcode_child of child.children ) {
              this.renderer.setStyle ( barcode_child
                                     , 'width'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('width')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'height'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('height')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'font-size'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('font-size')
                                                        , factor
                                                        )
                                     );

              this.renderer.setStyle ( barcode_child
                                     , 'letter-spacing'
                                     , this.getNewValue ( window.getComputedStyle(barcode_child, null).getPropertyValue('letter-spacing')
                                                        , factor
                                                        )
                                     );
            }
          }
        }
      }
    }
  }

  getNewValue( $oldValue, $factor ) {
    return ( +$oldValue.replace('px', '') * $factor) + 'px';
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );
    await this.initialize();
    this.getScreenSize();
    this.router.toggleshowwait( false );
  }

  async initialize() {
    this.initialized = false;

    if ( this.list.getValue( this.router.persondata, 'nameOnCard' , true )+'' == '' ) {
      let nameoncard = (   this.list.getValue( this.router.persondata, 'firstName' , true )
                         + ' '
                         + this.list.getValue( this.router.persondata, 'middleName' , true )
                         + ' '
                         + this.list.getValue( this.router.persondata, 'lastName' , true )
                       ).replace('  ', ' ');

      this.router.persondata['nameOnCard'] = nameoncard;
    }

    this.startdate   = this.date.datetostring ( this.list.getValue( this.router.persondata, 'cardStartDate', true ), this.date.dateformat, false );
    this.activatedon = this.date.datetostring ( this.list.getValue( this.router.persondata, 'activatedOn',   true ), this.date.dateformat, false );
    this.enddate     = this.date.datetostring ( this.list.getValue( this.router.persondata, 'cardEndDate',   true ), this.date.dateformat, true );
    this.expdate     = this.date.datetostring ( this.list.getValue( this.router.persondata, 'cardEndDate',   true ), this.date.dateformat, true );

    if ( this.photo.photoResult != undefined && this.photo.photoResult != '' ) {
      if ( this.photo.photoResult.indexOf('data:image/jpg;base64,') == -1 ) {
        this.currentPhoto = 'data:image/jpg;base64,'+this.photo.photoResult.replace('data:image/jpeg;base64,', '');
      } else {
        this.currentPhoto = this.photo.photoResult;
      }
    } else if ( this.list.getValue( this.router.persondata, 'photo', true ) != '' ) {
      this.currentPhoto = 'data:image/jpg;base64,'+this.list.getValue( this.router.persondata, 'photo', true );
    } else {
      this.currentPhoto = 'assets/images/photo_template.jpg';
    }

    this.cardaslink = this.environment.getEnvValue('cardpreview') && this.environment.getEnvValue('cardaslink');

    this.title = this.cardaslink ? 'welcome.cardinfo' : '';

    this.initialized = true;
  }

  getClass() {
     let classList;

     if (   this.list.getValue( this.router.persondata, 'firstName', true )
          + this.list.getValue( this.router.persondata, 'lastName', true )
          + this.list.getValue( this.router.persondata, 'photo', true )  == ''
        ) {
       classList = 'blancocard';
     } else {
       classList = this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase();
       classList += this.environment.getEnvValue('langimage') ? ' ' + this.language.currentLanguage : '';
       classList += this.cardaslink ? ' w3-hover-shadow pointer' : '';
     }

     return classList;
  }

  showInfo() {
    if ( this.cardaslink ) {
      this.router.goTo('cardinfo');
    }
  }

  stripCardNumber() {
    let cardNumber = this.list.getValue( this.router.persondata, 'cardNumber', true );

    if ( cardNumber != undefined ) {
      if ( cardNumber.length > 10 ) {
        return cardNumber.substr(cardNumber.length - 10);
      } else {
        return cardNumber;
      }
    }

    return '';  
  }

  ngOnDestroy() {
    return;
  }
}

<div #cardpreviewcontainer id="cardpreviewcontainer" class="w3-margin" *ngIf="initialized">
  <div
    #cardpreview
    id      = "cardpreview"
    class   = "cardpreview"
    [class] = "getClass()"
    (click) = "showInfo()"
    title   = "{{ title | translate: router.transparams }}"
  >
    <img *ngIf="currentPhoto != ''" class="photo" alt="photo" src="{{currentPhoto}}">
    <div class="name">
      <div class="title"       >{{ list.getValue( this.router.persondata, 'title', true ) }}</div>
      <div class="initials"    >{{ list.getValue( this.router.persondata, 'initials'   , true ) }}</div>
      <div class="firstname"   >{{ list.getValue( this.router.persondata, 'firstName'  , true ) }}</div>
      <div class="middlename"  >{{ list.getValue( this.router.persondata, 'middleName' , true ) }}</div>
      <div class="lastname"    >{{ list.getValue( this.router.persondata, 'lastName'   , true ) }}</div>
      <div class="nameoncard"  >{{ list.getValue( this.router.persondata, 'nameOnCard' , true ) }}</div>
    </div>
    <div class="loginid"       >{{ list.getValue( this.router.persondata, 'LoginID' , true ) }}</div>
    <div class="group"         >{{ 'cardpreview.'+list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() | translate }}</div>
    <div class="departement"   >{{ list.getValue( this.router.persondata, 'DepartmentName'   , true ) }}</div>
    <div class="location"      >{{ list.getValue( this.router.persondata, 'LocationName'     , true ) }}</div>
    <div class="personnumber"  >{{ list.getValue( this.router.persondata, 'personNumber'     , true ) }}</div>
    <div class="origregnr" *ngIf="this.router.freefields['personfreefields'] != undefined">{{ list.getValue( this.router.freefields['personfreefields'], 'freefield06', false ) }}</div>
    <div class="profilenumber" >{{ list.getValue( this.router.persondata, 'profileNumber'    , true ) }}</div>
    <div class="cardnumber"    >{{ list.getValue( this.router.persondata, 'cardNumber'       , true ) }}</div>
    <div class="cardnumber2"   >{{ stripCardNumber() }}</div>
    <div class="startdate"     >{{ startdate   }}</div>
    <div class="enddate"       >{{ enddate     }}</div>
    <div class="activatedon"   >{{ activatedon }}</div>
    <div class="expdate"       >{{ expdate     }}</div>
    <omnicard-barcode class="barcode" code="{{ list.getValue( this.router.persondata, 'cardNumber', true )}}" [displayvalue]="true"></omnicard-barcode>
    <omnicard-barcode *ngIf="this.router.freefields['cardholderfreefields'] != undefined" class="barcode2" code="{{ list.getValue( this.router.freefields['cardholderfreefields'], 'freeField01', true )}}" [displayvalue]="true"></omnicard-barcode>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DateService }       from '@omnipas/services/date.service';
import { ListService }       from '@omnilib/services/list.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-cardinfo',
  templateUrl: './cardinfo.component.html'
})
export class CardInfoComponent implements OnInit, OnDestroy {

  initialized: boolean = false

  constructor ( public date:       DateService
              , public list:       ListService
              , public pageheader: PageHeaderService
              , public router:     RouterService
              ) {
  }

  ngOnInit() {
    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader ( 'pagetitle.cardinfo', 'pagetext.cardinfo' );
    }

    this.router.toggleshowwait( false );
    this.initialized = true;
  }

  back() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
}

<div id="aboutcontent" *ngIf="router.transparams.customer_shortname != ''" class="w3-modal-content w3-display-content w3-animate-zoom w3-card-4" [class]="router.transparams.customer_shortname == 'novacollege' ? 'w3-theme-l4' : 'w3-theme-l5'">
  <header class="w3-theme-l3 w3-display-container w3-border-bottom w3-border-theme">
    <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="hideAbout()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
    <h2 class="w3-display-left w3-margin-left fullheight">{{'pagetitle.about' | translate: router.transparams }}</h2>
  </header>
  <div class="w3-container w3-margin-top">
    <p>{{ 'about.line1' | translate: router.transparams }}</p>
    <p>{{ 'about.line2' | translate: router.transparams }}</p>
    <ul>
      <li *ngIf="environment.getEnvValue('cardpreview')">{{ 'about.list.cardpreview' | translate: router.transparams }}</li>
      <li *ngIf="!environment.getEnvValue('savephotoonserver')">{{ 'about.list.cardstatus' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('uploadphoto') && environment.getEnvValue('webcam')">{{ 'about.list.uploadandwebcam' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('uploadphoto') && !environment.getEnvValue('webcam')">{{ 'about.list.onlyupload' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('webcam') && !environment.getEnvValue('uploadphoto')">{{ 'about.list.onlywebcam' | translate: router.transparams }}</li>
      <li *ngIf="!environment.getEnvValue('savephotoonserver')">{{ 'about.list.carddata' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('cardactivate')">{{ 'about.list.cardactivate' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('cardblock')">{{ 'about.list.cardblock' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('cardrequest')">{{ 'about.list.cardrequest' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('licenseplate')">{{ 'about.list.licenseplate' | translate: router.transparams }}</li>
      <li *ngIf="environment.getEnvValue('chargingcard')">{{ 'about.list.chargingcard' | translate: router.transparams }}</li>
    </ul>
    <div *ngIf="router.transparams.customer_shortname != 'Horizon' && router.transparams.customer_shortname != 'Inholland' && router.transparams.customer_shortname != 'novacollege' && router.transparams.customer_shortname != 'noorderpoort' ">
      <p *ngIf="router.transparams.customer_shortname != 'HVA' && router.transparams.customer_shortname != 'UVA' && !environment.getEnvValue('savephotoonserver') && router.transparams.url != ''">{{ 'about.line3' | translate: router.transparams }}&nbsp;<a href="{{router.transparams.url}}" target="_blank"><b>{{ 'about.urltext' | translate: router.transparams }}</b></a></p>
      <div *ngIf="router.transparams.customer_shortname == 'Fontys'" [innerHTML]="'about.line3' | translate: router.transparams"></div>
      <p class="extralineheight" [innerHTML]="'about.line4' | translate: router.transparams"></p>
      <p>
        <span *ngIf="environment.getEnvValue('phone')">
          <fa-icon id="icon-phone" [icon]="icons.faphone"></fa-icon>
          &nbsp;<a href="tel:{{environment.getEnvValue('phone')}}">{{environment.getEnvValue('phone')}}</a>
          <span *ngIf="environment.getEnvValue('phone2')">
            &nbsp;/&nbsp;<a href="tel:{{environment.getEnvValue('phone2')}}">{{environment.getEnvValue('phone2')}}</a>
         </span>
        </span>
        <br/>
        <span *ngIf="environment.getEnvValue('email')">
          <fa-icon id="icon-mail" [icon]="icons.faat"></fa-icon>&nbsp;<a href="mailto:{{environment.getEnvValue('email')}}">{{environment.getEnvValue('email')}}</a>
        </span>
      </p>
      <p *ngIf="environment.getEnvValue('terms')">
        <b>{{ 'about.terms' | translate: router.transparams }}:&nbsp;</b>{{ 'about.click' | translate: router.transparams }}&nbsp;<a href="{{environment.getEnvValue('terms')}}" target="_blank"><b>{{ 'about.urltext' | translate: router.transparams }}</b></a>
      </p>
    </div>
    <div *ngIf="router.transparams.customer_shortname == 'novacollege'">
      <p>{{ 'about.line3' | translate: router.transparams }}&nbsp;<a class="link" (click)="showMoreinfo()"><b>{{ 'about.urltext' | translate: router.transparams }}</b>.</a></p>
    </div>
    <div *ngIf="router.transparams.customer_shortname == 'Horizon'">
      <p>
        <span><b>Ben je student?</b></span><br/>
        <span>
          Je krijgt jouw Horizonpas thuisgestuurd nadat de onderwijsovereenkomst is ondertekend. Heb je hulp nodig, neem dan contact op met de studentenhelpdesk,
          via mail <a class="link" href="mailto:studentenhelpdesk&#64;horizoncollege.nl">studentenhelpdesk&#64;horizoncollege.nl</a>
          of bel <a class="link" href="tel:+31725476860">072 547 6860</a>
        </span>
      </p>
      <p>
        <span><b>Ben je medewerker?</b></span><br/>
        <span>Je krijgt jouw Horizonpas thuisgestuurd, tenzij je een externe medewerker bent. In dat geval kun je jouw Horizonpas ophalen bij het Servicepunt. Heb je hulp nodig, neem dan contact op met
          <a class="link" href="mailto:servicepunt&#64;horizoncollege.nl">servicepunt&#64;horizoncollege.nl</a>
        </span>
      </p>
      <p>Meer informatie over de Horizonpas vind je op ConnectMe in Weten & Regelen. Je kunt ook zoeken in de zoekbalk bovenaan.</p>
    </div>
    <p class="w3-right-align w3-padding w3-small">{{ 'footer.copyright' | translate: {year: year} }}</p>
  </div>
  <footer class="w3-theme-l3 w3-border-top w3-border-theme">
  </footer>
</div>

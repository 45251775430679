import { Injectable } from '@angular/core';
import { DataService }   from '@omnilib/services/data.service';
import { ListService }   from '@omnilib/services/list.service';
import { RouterService } from '@omnipas/services/router.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAddressService {
  constructor( public data:   DataService
             , public list:   ListService
             , public router: RouterService
             ) {
  }

  found:        boolean = false;
  addressLine1: string  = '';
  addressLine2: string  = '';


  async get() {
    let result = await this.data.callapi ( 'get', 'freefield/person', {} );

    if ( result['result']['code'] && result['result']['code'] == '0' && !this.list.emptyList( result['result']['resultset'] ) ) {
      this.found = true;
    }
  }

  async update() {
    await this.get();

    let params = { "freeField07" : this.addressLine1
                 , "freeField08" : this.addressLine2
                 }

    let result = await this.data.callapi ( this.found ?  'put' : 'post', 'freefield/person', params );

    if ( result['result']['code'] && result['result']['code'] == '0' ) {
      return true;
    } else {
      return false;
    }
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthConfig, OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { LoginService }       from '@omnipas/services/login.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PersonService }      from '@omnipas/services/person.service';
import { RouterService }      from '@omnipas/services/router.service';

import { Observable, throwError, of, from } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'omnipas-surflogin',
  templateUrl: './surflogin.component.html'
})
export class SurfLoginComponent implements OnInit, OnDestroy {

  claims = {};
  initialized: boolean = false;

  constructor ( public data:         DataService
              , public environment:  EnvironmentService
              , public list:         ListService
              , public loading:      LoadingService
              , public login:        LoginService
              , public message:      MessageService
              , public oauthService: OAuthService
              , public pageheader:   PageHeaderService
              , public person:       PersonService
              , public router:       RouterService
              ) {

    this.router.loggingin = true;
    this.connect();
  }

  async connect() {
    const surfConfig = this.environment.getEnvValue('surfConfig');

    if ( !this.list.emptyList(surfConfig) ) {

      this.router.loggingin = true;
      this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );

      let authConfig: AuthConfig = {};

      authConfig.issuer                   = this.list.getValue(surfConfig, 'issuer', true);
      authConfig.clientId                 = this.list.getValue(surfConfig, 'clientId', true);
      authConfig.redirectUri              = this.list.getValue(surfConfig, 'redirectUri', true);
      authConfig.logoutUrl                = this.list.getValue(surfConfig, 'logoutUrl', true);
      authConfig.silentRefreshRedirectUri = this.list.getValue(surfConfig, 'silentRefreshRedirectUri', true);
      authConfig.scope                    = this.list.getValue(surfConfig, 'scope', true);
      authConfig.requireHttps             = false;

      await this.oauthService.configure(authConfig);
      await this.oauthService.setStorage(sessionStorage);

      this.oauthService.loadDiscoveryDocumentAndLogin({
        onTokenReceived: context => {
          this.login.loginsso ( context['accessToken'] );
        }
      });

      this.router.loggingin = false;
    }
  }

  async ngOnInit() {
    if ( document.location.href.endsWith('surflogin')  ) {
      this.submit();
    } else {
      this.router.toggleshowwait( true );
      this.initialized = false;
      this.pageheader.setPageHeader( 'pagetitle.surfconext', 'pagetext.surfconext' );
      this.initialized = true;
      this.router.toggleshowwait( false );
    }
  }

  async submit() {
    await this.oauthService.initImplicitFlow();
  }

  ngOnDestroy() {
    return;
  }

}

export const environment = {
  production: true,
  apiurl: 'https://soap.omnicms3.nl:9005/api/v1/',
  mock: false,
  config: {
    product: "Mijn Fontyskaart",
    customer_fullname: "Fontys",
    customer_shortname: "Fontys",
    url: "https://connect.fontys.nl/diensten/IT/manuals/Paginas/StudentCard.aspx",
    phone: "",
    email: "fontyskaart@fontys.nl",
    MFAemail: false,
    MFAsms: false,
    logintype : "surf",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: { issuer: "https://connect.surfconext.nl"
                , clientId: "www.fontyskaart.nl"
                , redirectUri: "https://www.fontyskaart.nl/site/surflogin"
                , silentRefreshRedirectUri: "https://www.fontyskaart.nl/site/silent-refresh.html"
                , logoutUrl: "https://engine.surfconext.nl/logout"
                , scope: "openid profile email"
                , loginItem: "uids"
                , oidc: true
                , skipPage: true
                , secret: "s0VkFgxuJTl0xwWOVlIa"
    },
    adfsConfig: {},
    ingenico: { url: "https://secure.ogone.com/ncol/prod/orderstandard.asp"
              , orderid: ""
              , pspid: "875627"
              , sha_in_key: "R@G3#d373B#!12JfP02H"
              , sha_out_key: "EeJkw2sk12#gG28d1pQ9"
              , currency: "EUR"
              , bgcolor: "#F3F3F3"
              , txtcolor: "#004C93"
              , tblbgcolor: "#FFFFFF"
              , tbltxtcolor: "#666666"
              , buttonbgcolor: "#004C93"
              , buttontxtcolor: "#FFFFFF"
              , fonttype: "Verdana"
              , pm: "iDEAL"
              , homeurl: "https://www.fontyskaart.nl/site/payment"
              , accepturl: "https://www.fontyskaart.nl/site/payment/success"
              , declineurl: "https://www.fontyskaart.nl/site/payment/decline"
              , exceptionurl: "https://www.fontyskaart.nl/site/payment/fail"
              , cancelurl: "https://www.fontyskaart.nl/site/payment/cancel"
    },
    cardprices: { "Student": 1000, "Employee": 0, "External employee": 0, "default": 1000 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "pas"
      },
      {
        id: 2,
        LanguageCode: "en",
        LanguageName: "English",
        Card: "pass"
      },
    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: false,
    chargingcard: true,
    digitalcard: false,
    uploadphoto: true,
    webcam: true,
    licenseplate: true,
    nameoncard: false,
    cardpreview: true,
    cardaslink: false,
    cardresult: false,
    cardactivate: true,
    cardblock: true,
    carddeblock: true,
    cardrequest: true,
    logoutbutton: true,
    requestnewpassword: false,
    publishPermission: true,
    scrollButtons: true,
    breadcrumbs: false,
    versioninfooter: false,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: false,
    firstnameonwelcome: true,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: false,
    langimage : false,
    maintainance: false,
    timeout: 180,
  },
  ssl: true
};
